<template>
  <!-- prettier-ignore -->
  <div
		ref="scrollerRef"
		class="scroller"
		:class="{'scroller--overflowing': isOverflowing}"
	>
		<slot name="left">
			<div
				v-show="controls.prev"
        class="control"
        tabindex="0"
        @click="$emit('select-prev')"
      >
        <the-icon
          art="regular"
          name="arrow-left"
        />
      </div>
		</slot>

		<div class="overflow">
			<slot name="items"></slot>
		</div>

		<slot name="right">
			<div
				v-show="controls.next"
        class="control"
        tabindex="0"
        @click="$emit('select-next')"
      >
        <the-icon
          art="regular"
          name="arrow-right"
        />
      </div>
		</slot>
	</div>
</template>

<script setup>
import { computed, nextTick, onUnmounted, reactive, ref, watch } from 'vue'

// INIT
defineEmits(['select-next', 'select-prev'])
const props = defineProps({
  byIndex: {
    type: Number,
    default: -1,
  },

  disableControls: {
    type: Boolean,
    default: false,
  },
})

// DATA
const scrollerRef = ref(null)
const controls = ref({
  prev: false,
  next: false,
})
const state = reactive({
  el: null,

  fullWidth: 0,
  width: 0,
  count: 0,

  moved: false,

  x: 0,
  left: 0,
})

// COMPUTED
const isOverflowing = computed(() => {
  return state.fullWidth > Math.round(state.width)
})

// METHODS
function onMouseDown(event) {
  if (!isOverflowing.value) return

  event.preventDefault()

  scrollerRef.value.style.cursor = 'grabbing'

  // reset moved state
  state.moved = false

  state.left = state.el.scrollLeft
  state.x = event.clientX

  document.addEventListener('mousemove', onMouseMove)
  document.addEventListener('click', onMouseUp)
  return false
}

function onMouseMove(event) {
  event.preventDefault()

  const x = event.clientX - state.x

  // set moved state
  state.moved = true

  state.el.scrollLeft = state.left - x
}

function onMouseUp(event) {
  // prevent all default actions, when mouse has beeen moved
  if (state.moved) {
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()
  }

  scrollerRef.value.style.cursor = 'grab'

  document.removeEventListener('mousemove', onMouseMove)
  document.removeEventListener('click', onMouseUp)
}

function recalc(element = null, reeval = false) {
  if (reeval) {
    state.el = element.querySelector('.overflow')
    state.count = state.el.children.length
  }

  state.fullWidth = state.el.scrollWidth
  state.width = state.el.getBoundingClientRect().width

  updateControls()
}

async function scrollToIndex(index) {
  if (props.byIndex === -1) return

  const child = state.el.children[index]

  await nextTick()

  // first child
  if (index === 0) {
    state.el.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  } else {
    let value = child.getBoundingClientRect().x

    if (controls.value.prev) {
      value = value - 75 // magic number => should ~equal width+margin of control-button
    } else if (index === 0) {
      value = 0
    }

    state.el.scrollBy({
      top: 0,
      left: value,
      behavior: 'smooth',
    })
  }
}

function updateControls() {
  if (props.disableControls === true) return

  // no item selected and overflowing => activate next
  if (props.byIndex === -1 && isOverflowing.value) {
    controls.value.prev = false
    controls.value.next = !!(state.count > props.byIndex + 1)

    // item selected and overflowing => if not first/last, activate prev/next
  } else if (props.byIndex !== -1 && isOverflowing.value) {
    controls.value.prev = !!(props.byIndex > 0)
    controls.value.next = !!(state.count > props.byIndex + 1)

    // deactivate all
  } else {
    controls.value.next = false
    controls.value.prev = false
  }
}

// WATCHER
watch(
  () => scrollerRef.value,
  element => {
    if (element) {
      recalc(element, true)

      element.addEventListener('mousedown', onMouseDown)
      window.addEventListener('resize', recalc)

      scrollToIndex(props.byIndex)
    }
  }
)

watch(
  () => props.byIndex,
  index => {
    updateControls()
    scrollToIndex(index)
  }
)

onUnmounted(() => {
  window.removeEventListener('resize', recalc)
})

defineExpose({ isOverflowing, recalc, el: scrollerRef })
</script>

<style scoped>
.scroller {
  user-select: none;
  display: flex;
  flex-direction: row;
  gap: var(--fixed-spacing-fix-01);

  .overflow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;

    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
  }

  &--overflowing .overflow {
    cursor: grab;
  }
}
</style>
